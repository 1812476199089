import {useCallback, useEffect, useState} from 'react';

import {
  getAuth,
  // @ts-ignore
} from '../Helpers/Firebase/firebase';

// @ts-ignore
// import {apiGetMemberInfo} from '../../Helpers/apiCalls';

import {getLocalData, saveLocalData} from 'src/Helpers/localStorage';
import {
  tryEmailPasswordLogin,
  tryFacebookLogin,
  tryGoogleLogin,
  tryEmailPasswordCreate,
} from '../Helpers/loginHelpers';
import {adminApiCall} from 'src/Helpers/apiCalls';
import {useRouter} from 'next/router';

export default function useFirebaseAuth({setUserSQL}) {
  const [user, setUser] = useState(null);
  const [firebaseIdToken, setFirebaseIdToken] = useState(
    getLocalData('firebaseIdToken'),
  );
  const [myUserId, setMyUserId] = useState(getLocalData('userId'));
  console.log({myUserId});
  const router = useRouter();
  const [disabledNewsletter, setDisabledNewsletter] = useState(false);

  const getUserSQLData = (user) => {
    adminApiCall('apiAdminGetUserSQL', {
      phoneNumber: user?.phoneNumber,
      email: user?.email,
      firebaseIdToken: user?.accessToken,
    })
      .then(async (response) => {
        console.log({response});
        if (response?.userInfo?.uuid) {
          setUser(user);
          setMyUserId(response?.userInfo?.uuid);
          setUserSQL(response?.userInfo);
          await saveLocalData('userId', response?.userInfo?.uuid);
          await saveLocalData('userInfo', JSON.stringify(response?.userInfo));
          if (window.reloadOnSignIn) {
            window.reloadOnSignIn = 0;
            router.replace(router.asPath);
          }
        } else {
          alert(
            'An eror occured, please contact mike!' + JSON.stringify(userInfo),
          );
        }
      })
      .catch((e) => console.log('error', e));
  };

  useEffect(() => {
    console.log('my user id change: ', {myUserId});
  }, [myUserId]);

  useEffect(() => {
    // @ts-ignore
    if (!getLocalData('userId')) {
      signOut();
    }
  }, []);

  // getting the initial user from firebase auth
  useEffect(() => {
    console.log('adding auth listener');
    getAuth().onAuthStateChanged((user) => {
      console.log('firebase auth (not data) done ', user);
      saveLocalData('firebaseIdToken', user?.accessToken);
      setFirebaseIdToken(
        user?.accessToken !== 'null' ? user?.accessToken : null,
      );
      if (user?.email) {
        getUserSQLData(user);
      } else if (window.global?.phoneUserData) {
        getUserSQLData({
          email: global.phoneUserData?.email,
          phoneNumber: global.phoneUserData?.phone,
          displayName: global.phoneUserData?.name,
        });
      } else if (user?.phoneNumber) {
        getUserSQLData(user);
      } else {
        setMyUserId(null);
        setUser(null);
        signOut();
      }
    });
  }, []);

  const signIn = useCallback(async (providerType, extraData) => {
    window.reloadOnSignIn = 1;
    console.log(providerType);
    let user;
    if (providerType === 'facebook') {
      user = await tryFacebookLogin();
      console.log({user, email: user?.email});
      setUser(user);
      getUserSQLData(user);
    } else if (providerType === 'google') {
      user = await tryGoogleLogin();
      console.log({user: user.user, email: user?.user?.email});
      setUser(user.user);
      getUserSQLData(user.user);
    } else if (providerType === 'password') {
      console.log('trying email sign in');
      user = await tryEmailPasswordLogin(extraData?.email, extraData?.password);
      console.log({user: user.user, email: user?.user?.email});
      setUser(user.user);
      getUserSQLData(user.user);
    } else if (providerType === 'password-create') {
      console.log('trying email create');
      user = await tryEmailPasswordCreate(
        extraData?.email,
        extraData?.password,
      );
      console.log({user: user.user, email: user?.user?.email});
      setUser(user.user);
      getUserSQLData(user.user);
      setTimeout(() => {
        window.location.href = '/client/jobs';
      }, 300);
    } else if (providerType === 'text') {
      const auth = getAuth();
      const authObject = getAuth();
      const provider = new authObject.PhoneAuthProvider();
      var credential = authObject.PhoneAuthProvider.credential(
        extraData.verificationId,
        extraData.verificationCode,
      );
      console.log({credential});
      const data = auth.signInWithCredential(credential);
      console.log({data});
    }
  }, []);

  const signOut = useCallback(() => {
    return getAuth()
      .signOut()
      .then(() => {
        setUser(null);
        console.log('deleting user cookie');
        // @ts-ignore
        saveLocalData('userInfo', null);
        saveLocalData('userId', null);
        saveLocalData('myCompanyUUID', null);
        saveLocalData('firebaseIdToken', null);
        setFirebaseIdToken(null);
      });
  }, []);

  return {
    myUserId,
    user,
    signIn,
    signOut,
    disabledNewsletter,
    setDisabledNewsletter,
    firebaseIdToken,
  };
}
