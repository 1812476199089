// import {GoogleSignin} from '@react-native-community/google-signin';

// @ts-ignore
import {getAuth} from './Firebase/firebase';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
} from 'firebase/auth';

// import {
//   LoginManager,
//   AccessToken,
//   GraphRequest,
//   GraphRequestManager,
//   // @ts-ignore
// } from 'react-native-fbsdk';

export const tryGoogleLogin = async () => {
  try {
    let provider;
    const auth = getAuth();
    provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/plus.login');
    const userFirebaseData = await signInWithPopup(auth, provider);
    return userFirebaseData;
  } catch (err) {
    console.log({err});
    throw new Error(err);
  }
};

export const tryEmailPasswordCreate = async (email, password) => {
  if (email?.includes('letsroam') || email?.includes('letshire')) {
    return alert(`Forbidden login for Let's team. Please use google sign in.`);
  }
  try {
    console.log({email, password});
    const auth = getAuth();
    const userFirebaseData = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    if (userFirebaseData) {
      sendSignInLinkToEmail(auth, email, {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://www.letshire.io/jobs/edit',
        // This must be true.
        handleCodeInApp: true,
      });
    }
    return userFirebaseData;
  } catch (err) {
    console.log({err});
    alert(err);
    throw new Error(err);
  }
};

export const tryEmailPasswordLogin = async (email, password) => {
  if (email?.includes('letsroam') || email?.includes('letshire')) {
    return alert(`Forbidden login for Let's team. Please use google sign in.`);
  }
  try {
    let provider;
    const auth = getAuth();
    provider = new GoogleAuthProvider();
    const userFirebaseData = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return userFirebaseData;
  } catch (err) {
    console.log({err});

    alert(err);

    throw new Error(err);
  }
};

export const tryPasswordReset = async (email = '') => {
  if (!email) {
    alert('Please enter an email to reset');
  }
  if (email?.includes('letsroam') || email?.includes('letshire')) {
    return alert(`Forbidden login for Let's team. Please use google sign in.`);
  }
  try {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url:
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3000/jobs/edit'
          : 'https://www.letshire.io/jobs/edit',
      // This must be true.
      handleCodeInApp: true,
    });
  } catch (err) {
    console.log({err});

    alert(err);

    throw new Error(err);
  }
};

export const tryFacebookLogin = async () => {
  console.log('try facebook login');
  try {
    let provider;
    const auth = getAuth();

    provider = new FacebookAuthProvider();
    provider.addScope('email');
    const userFirebaseData = await signInWithPopup(auth, provider);
    return userFirebaseData;
  } catch (err) {
    console.log('an error occurred');
    console.log(err);
  }
};
