import React, {createContext, useContext, useState, useMemo} from 'react';
import {useEffect} from 'react';
import {apiAdminGetCompanyInfo} from '../Helpers/apiCalls';
import {getLocalData, saveLocalData} from '../Helpers/localStorage';
import {useSafeNavigationProvider} from './SafeNavigationProvider';
import useFirebaseAuth from 'src/Providers/useFirebaseAuth';
import {useRouter} from 'next/router';
import Cookies from 'js-cookie';

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function UserProvider({children, serverData}) {
  console.log('Provider Update: UserProvider');
  const route = useRouter();
  // adding the event state info

  const [userSQL, setUserSQL] = useState(
    serverData?.userInfo || getLocalData('userInfo') || false,
  );

  const {
    myUserId,
    user,
    signIn,
    signOut,
    firebaseIdToken,
    userInfo,
    disabledNewsletter,
    setDisabledNewsletter,
  } = useFirebaseAuth({setUserSQL});

  const isAdmin = userInfo?.email?.includes('@letsroam.com');

  console.log('UserProvider', {serverData});

  const [company, setCompany] = useState(serverData?.company || false);
  const [myCompanyUUID, setMyCompanyUUID] = useState(serverData?.company?.uuid);
  console.log({myCompanyUUID});
  const [error, setErrorFunction] = useState(false);

  useEffect(() => {
    console.log('UserProvider request time change');
    if (serverData?.userInfo) {
      setUserSQL(serverData?.userInfo || false);
    }
    if (serverData?.company) {
      setCompany(serverData?.company || false);
    }

    if (serverData?.company?.uuid) {
      setMyCompanyUUID(serverData?.company?.uuid);
    }
  }, [serverData?.requestTime]);

  const setError = (error) => {
    console.log('setting error', {error});
    setErrorFunction(error);
  };

  const {safeNavigate} = useSafeNavigationProvider();

  const contextValue = {
    myUserId,
    user,
    signIn,
    signOut,
    firebaseIdToken,
    userInfo,
    disabledNewsletter,
    setDisabledNewsletter,
    isAdmin,
    userSQL,
    setUserSQL,
    company,
    setCompany,
    myCompanyUUID,
    setMyCompanyUUID,
    error,
    setError,
  };

  useEffect(() => {
    console.log('checking if should fetch company info');
    if (
      firebaseIdToken &&
      firebaseIdToken !== 'null' &&
      myUserId &&
      myUserId !== 'null'
    ) {
      console.log('getting profile data', {firebaseIdToken, myUserId});
      apiAdminGetCompanyInfo({
        firebaseIdToken,
        user_uuid: myUserId,
        refering_company_uuid: Cookies.get('refering_company_uuid'),
      }).then((profileData) => {
        console.log({profileData});
        profileData?.userInfo && setUserSQL(profileData.userInfo);

        if (profileData?.error) {
          setError(profileData.error);
        } else if (profileData?.companyInfo) {
          console.log('setting company', {
            companyInfo: profileData.companyInfo,
          });
          setCompany(profileData.companyInfo);
          saveLocalData('myCompanyUUID', profileData.companyInfo?.uuid);
          setMyCompanyUUID(profileData.companyInfo?.uuid);
        } else if (
          profileData?.userInfo &&
          !profileData?.userInfo?.email?.includes('@letsroam')
        ) {
          console.log('no company info!');
          saveLocalData('myCompanyUUID', null);
          setCompany(null);
          if (!window.location.href?.includes('/profile/create')) {
            window.location.href =
              `/client/profile/create?myUserId=` + myUserId;
          }
        }
        console.log({profileData});
      });
    }
  }, [firebaseIdToken, myUserId]);

  return (
    <StateContext.Provider value={{...contextValue}}>
      {children}
    </StateContext.Provider>
  );
}

export function useUserState() {
  const context = useContext(StateContext);
  useEffect(() => {
    if (!window.state) {
      window.state = {};
    }
    window.state.UserProvider = context;
  }, [context]);
  if (!context) {
    return {
      userInfo: null,
      setUserInfo: null,
    };
  }
  return context;
}
