import {isAdmin} from '@firebase/util';
import React from 'react';
import {Text, View} from 'react-native';
import Card from '../Common/Card';
import BottomNavBar from './BottomNavBar';
import NavBar from './TopNavBar';
import {useMediaQuery} from '../Providers/MediaProvider';
import TopBar from './TopBar';
import {useUserState} from '../Providers/UserProvider';
import {ContinueButton} from '../Common/Text';
import {useModalState} from '../Providers/ModalProvider';
import {useRouter} from 'next/router';
import {Linking, TouchableOpacity} from 'react-native-web';
import TechSupportButton from 'src/Common/TechSupportButton';

const AdminPage = ({
  data = false,
  children,
  stageDone = false,
  showAdmin = false,
  topBar = false,
  maxContentWidth = 800,
  whiteHeader = 0,
  hideBottomPadding = false,
  seoTitle = "Let's Hire",
  seoDescription = "Let's Hire is the easiest way to hire people!",
  seoImage = 'https://www.letsroam.com/assets/img/Lets_Hire_Logo_horizontal.png',
  hideSeo = false,
}) => {
  const offset = topBar ? 120 : 50;
  const {h1Style, isDesktop, isSmallScreen, bodyStyle} = useMediaQuery();
  const {error, signOut} = useUserState();
  const {setLoginModalOpen} = useModalState();
  const router = useRouter();
  return (
    <View>
      <NavBar showAdmin={showAdmin} whiteHeader={whiteHeader} />
      {!!topBar && !isSmallScreen && <TopBar {...topBar} />}
      <View
        nativeID="AdminPage"
        style={{
          backgroundColor: 'rgb(231, 240, 243)',
          height: `calc(100vh - ${offset}px)`,
          display: 'flex',
          alignItems: 'center',
          overflow: 'scroll',
        }}>
        {!!topBar && isSmallScreen && <TopBar {...topBar} />}
        <View
          style={{
            maxWidth: maxContentWidth,
            width: '100%',
            padding: isDesktop ? 20 : 10,
            height: '100%',
          }}>
          {!data && !error && !stageDone && (
            <Card>
              <Text style={{...bodyStyle}}>Loading</Text>
            </Card>
          )}
          {!!error && !stageDone && (
            <Card topLine={'red'}>
              <Text style={h1Style}>{error.title || 'error'}</Text>
              <Text style={{...bodyStyle}}>{error.message}</Text>
              {error?.code === 'invalid_user' && (
                <ContinueButton
                  text={'Sign In & Out'}
                  containerStyle={{maxWidth: 250, marginTop: 20, marginLeft: 0}}
                  handlePress={async () => {
                    await signOut();
                    setLoginModalOpen({open: true});
                  }}
                />
              )}
            </Card>
          )}
          {!!stageDone && (
            <Card topLine={'green'}>
              <Text style={{...bodyStyle, h1Style}}>
                Thank You For Submitting This Stage Of The Application
              </Text>
              <Text style={{...bodyStyle, marginTop: 10}}>
                We will get back to you shortly with next steps. If you had any
                techinical issues with your application please report them to
                our CTO: hiring@letsroam.com.
              </Text>
            </Card>
          )}
          {/* {process.env.NODE_ENV == 'development' && <a>Skip Ahead</a>} */}
          {data && !error && !stageDone && !!children && children}
        </View>
      </View>
      <View style={{height: 100, width: 100}}></View>
      {(!!isAdmin || router.basePath.includes('client/')) && <BottomNavBar />}
      <TechSupportButton />
    </View>
  );
};

export default AdminPage;
