import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import {useMediaQuery} from '../Providers/MediaProvider';
import {useUserState} from '../Providers/UserProvider';
import {useRouter} from 'next/router';

import Icon from 'src/Common/Icon';
import {useSafeNavigationProvider} from '../Providers/SafeNavigationProvider';

export default function BottomNavBar({showAdmin = false}) {
  const {userSQL, signOut, myUserId} = useUserState();
  const router = useRouter();

  const {isSmallScreen} = useMediaQuery();
  const route = useRouter();
  if (!isSmallScreen || router?.pathname?.includes('/apply/')) {
    return <View></View>;
  }
  return (
    <View
      style={{
        position: 'fixed',
        bottom: 0,
        boxShadow: '0 2px 2px -2px rgba(0,0,0,.2)',
        height: 60,
        left: 0,
        right: 0,
        backgroundColor: 'white',
      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
        }}>
        <FooterButton
          to={'/client/jobs'}
          text={'Jobs'}
          icon="list"
          isSelected={
            route.pathname === '/' || route.pathname === '/client/jobs'
          }
        />
        {userSQL?.email?.includes('@letsroam.') && (
          <FooterButton
            to={'/client/templates'}
            text={'Templates'}
            icon="copy"
            isSelected={route.pathname === '/client/templates'}
          />
        )}
        <FooterButton
          to={'/client/company/profile'}
          text={'Profile'}
          icon="user"
          isSelected={route.pathname === '/client/company/profile'}
        />
        {!!myUserId && (
          <FooterButton
            handlePress={() => signOut()}
            text={'Sign Out'}
            icon="sign-out"
          />
        )}
      </View>
    </View>
  );
}

const FooterButton = ({to, handlePress, text, icon, isSelected}) => {
  const {safeNavigate} = useSafeNavigationProvider();
  return (
    <TouchableOpacity
      onPress={() => {
        if (to) {
          return safeNavigate(to);
        }
        handlePress();
      }}
      style={{
        textDecorationLine: 'none',
        textDecorationColor: 'none',
        // border: '2px solid black',
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Icon
        size={20}
        name={icon}
        color={isSelected ? 'blue' : global.color.gray3}
        style={{marginBottom: 5}}
      />
      <Text
        style={{
          color: isSelected ? 'blue' : global.color.gray3,
          textAlign: 'center',
        }}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};
